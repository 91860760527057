<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="展会名称" prop="name">
                <a-input v-model="queryParam.name" placeholder="请输入称号名称" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="状态" prop="status">
                <a-select placeholder="请选择标签" style="width: 100%" v-model="queryParam.status" allowClear>
                  <a-select-option v-for="(item, index) in this.customDict.ExpoStatusEnum" :value="item.type"
                                   :key="index">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <template v-if="advanced">

              <a-col :md="8" :sm="24">
                <a-form-item label="开始时间" prop="startTime">
                  <a-date-picker style="width: 100%" @change="onDateChange" :showTime="true" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
<!--                  <j-date v-model="queryParam.startTime" :showTime="true" date-format="YYYY-MM-DD HH:mm:ss"  placeholder="请输入秒杀开始时间" ></j-date>-->
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="结束时间" prop="endTime">
                  <a-date-picker style="width: 100%" @change="onDateChange1" :showTime="true" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
                </a-form-item>
              </a-col>

            </template>
            <a-col :md="!advanced && 8 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <div class="table-operations">
        <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['expo:expo:add']">
          <a-icon type="plus" />新增
        </a-button>
        <a-button type="primary" :disabled="single" @click="$refs.createForm.handleUpdate(undefined, ids)" v-hasPermi="['expo:expo:edit']">
          <a-icon type="edit" />修改
        </a-button>
        <a-button type="danger" :disabled="multiple" @click="handleDelete" v-hasPermi="['expo:expo:remove']">
          <a-icon type="delete" />删除
        </a-button>
        <a-button type="primary" @click="handleQuery" v-hasPermi="['expo:expo:query']">
          <a-icon type="search" />查询
        </a-button>
<!--        <a-button type="primary" @click="handleExport" v-hasPermi="['expo:expo:export']">
          <a-icon type="download" />导出
        </a-button>-->
        <table-setting
          :style="{float: 'right'}"
          :table-size.sync="tableSize"
          v-model="columns"
          :refresh-loading="loading"
          @refresh="getList" />
      </div>
      <!-- 增加修改 -->
      <create-form
        ref="createForm"
        @ok="getList"
      />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
        :bordered="tableBordered"
      >
        <span slot="startTime" slot-scope="text, record">
          {{ parseTime(record.startTime) }}
        </span>
        <span slot="endTime" slot-scope="text, record">
          {{ parseTime(record.endTime) }}
        </span>
        <span slot="createTime" slot-scope="text, record">
          {{ parseTime(record.createTime) }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['expo:expo:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['expo:expo:edit']">
            <a-icon type="edit" />修改
          </a>
          <a-divider type="vertical" v-hasPermi="['expo:expo:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['expo:expo:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
        <span slot="status" slot-scope="text, record">
           <custom-dict-tag :options="customDict.ExpoStatusEnum" :value="record.status"/>
        </span>
        <span slot="cover" slot-scope="text, record">
          <img v-if="record.cover" :src="record.cover" preview style="width:60px;height:62px;" alt=""/>
        </span>
        <span slot="banner" slot-scope="text, record">
          <img v-if="record.banner" :src="record.banner" preview style="width:60px;height:62px;" alt=""/>
        </span>

      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="total => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
  </page-header-wrapper>
</template>

<script>
import { listExpo, delExpo } from '@/api/expo/expo'
import CreateForm from './modules/CreateForm'
import { tableMixin } from '@/store/table-mixin'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
export default {
  name: 'Expo',
  components: {
    CreateForm,
    CustomDictTag
  },
  mixins: [tableMixin],
  data () {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      // 查询参数
      queryParam: {
        name: null,
        cover: null,
        banner: null,
        scriptNum: null,
        city: null,
        startTime: null,
        endTime: null,
        status: undefined,
        browserValue: null,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        /*{
          title: 'id',
          dataIndex: 'id',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '展会名称',
          dataIndex: 'name',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '展会封面',
          dataIndex: 'cover',
          ellipsis: true,
          scopedSlots: { customRender: 'cover' },
          align: 'center'
        },
        /*{
          title: '展会详情',
          dataIndex: 'banner',
          ellipsis: true,
          scopedSlots: { customRender: 'banner' },
          align: 'center'
        },*/
        // {
        //   title: '参展剧本数',
        //   dataIndex: 'scriptNum',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '城市名称',
          dataIndex: 'city',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '开始时间',
          dataIndex: 'startTime',
          scopedSlots: { customRender: 'startTime' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          scopedSlots: { customRender: 'endTime' },
          ellipsis: true,
          align: 'center'
        },

        {
          title: '浏览量',
          dataIndex: 'browserValue',
          ellipsis: true,
          align: 'center'
        },
        /*{
          title: '创建时间',
          dataIndex: 'createTime',
          scopedSlots: { customRender: 'createTime' },
          ellipsis: true,
          align: 'center'
        },*/
       /* {
          title: '备注',
          dataIndex: 'remark',
          ellipsis: true,
          align: 'center'
        },*/
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {
  },
  created () {
    this.getList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  methods: {
    /** 查询展会列表 */
    getList () {
      this.loading = true
      listExpo(this.queryParam).then(response => {
        this.list = response.data.records
        this.total = response.data.total
        this.loading = false
      })
    },
    onDateChange(date, dateString) {
      console.log(date, dateString)
      this.queryParam.startTime = dateString
    },
    onDateChange1(date, dateString) {
      console.log(date, dateString)
      this.queryParam.endTime = dateString
    },
    /** 搜索按钮操作 */
    handleQuery () {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery () {
      this.queryParam = {
        name: undefined,
        cover: undefined,
        banner: undefined,
        scriptNum: undefined,
        city: undefined,
        startTime: undefined,
        endTime: undefined,
        status: undefined,
        browserValue: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange (current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize (current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map(item => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete (row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk () {
          return delExpo(ids)
            .then(() => {
              that.onSelectChange([], [])
              that.getList()
              that.$message.success(
                '删除成功',
                3
              )
          })
        },
        onCancel () {}
      })
    },
    /** 导出按钮操作 */
    handleExport () {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk () {
          that.download('expo/expo/export', {
            ...that.queryParam
          }, `expo_${new Date().getTime()}.xlsx`)
        },
        onCancel () {}
      })
    }
  }
}
</script>
