<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="展会名称" prop="name" >
        <a-input v-model="form.name" placeholder="请输入称号名称" />
      </a-form-model-item>
      <a-form-model-item label="展会封面" prop="cover">
        <a-upload
          name="cover"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="imgAvatarUpload">
          <img
            v-if="form.cover"
            :src="form.cover"
            alt="img"
            style="height: 102px; width: 102px; border-radius: 50%"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="分享封面" prop="shareCover">
        <a-upload
          name="shareCover"
          listType="picture-card"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="false"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="imgAvatarUploadShare">
          <img
            v-if="form.shareCover"
            :src="form.shareCover"
            alt="img"
            style="height: 102px; width: 102px; border-radius: 50%"/>
          <div v-else>
            <a-icon :type="loading ? 'loading' : 'plus'"/>
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
      <a-form-model-item label="展会详情" prop="banner">
        <a-upload
          name="info"
          v-model="fileList"
          listType="picture-card"
          :fileList="fileList"
          :remove="removeFile"
          class="avatar-uploader"
          :multiple="true"
          :show-upload-list="true"
          :preview="handlePreview"
          :before-upload="beforeOssUpload"
          :customRequest="imgAvatarUploadInfo">
          <div>
            <a-icon :type="loading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">上传</div>
          </div>
        </a-upload>
      </a-form-model-item>
<!--      <a-form-model-item label="参展剧本数" prop="scriptNum" >
        <a-input v-model="form.scriptNum" placeholder="请输入参展剧本数" />
      </a-form-model-item>-->
      <a-form-model-item label="城市名称" prop="city" >
        <a-input v-model="form.city" placeholder="城市名称" />
      </a-form-model-item>
      <a-form-model-item label="字体颜色" prop="mainColor" >
        <a-input v-model="form.mainColor" placeholder="字体颜色" />
      </a-form-model-item>
      <a-form-model-item label="背景颜色" prop="backgroundColor" >
        <a-input v-model="form.backgroundColor" placeholder="背景颜色" />
      </a-form-model-item>
      <a-form-model-item label="开始时间" prop="startTime" >
        <a-date-picker v-model="form.startTime" style="width: 100%" @change="onDateChange" :showTime="true" format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="结束时间" prop="endTime" >
        <a-date-picker v-model="form.endTime" style="width: 100%" @change="onDateChange1" :showTime="true"  format="YYYY-MM-DD HH:mm:ss" allow-clear/>
      </a-form-model-item>
      <a-form-model-item label="浏览值" prop="browserValue">
        <a-input-number v-model="form.browserValue" style="width: 100%" placeholder="请输入浏览值" :disabled="true" />
      </a-form-model-item>
      <a-form-model-item label="虚拟浏览值" prop="browserValueVirtual">
        <a-input-number v-model="form.browserValueVirtual" style="width: 100%" placeholder="请输入虚拟浏览值" />
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status">
        <a-select style="width: 100%" v-model="form.status" placeholder="请输入状态">
          <a-select-option v-for="(item, index) in this.customDict.ExpoStatusEnum" :value="item.type" :key="index">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
<!--      <a-form-model-item label="备注" prop="remark" >
        <a-input v-model="form.remark" placeholder="请输入内容" type="textarea" allow-clear />
      </a-form-model-item>-->
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getExpo, addExpo, updateExpo } from '@/api/expo/expo'
import {generateFilePath, uploadObject} from "@/api/tool/alioss";
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import { parseTime } from '../../../../utils/ruoyi'
export default {
  name: 'CreateForm',
  props: {
  },
  components: {
  },
  data () {
    return {
      loading: false,
      submitLoading: false,
      formTitle: '',
      fileList: [],
      // 表单参数
      form: {
        id: null,
        name: null,
        cover: null,
        shareCover: null,
        banner: null,
        mainColor: null,
        backgroundColor: null,
        scriptNum: null,
        city: null,
        startTime: null,
        endTime: null,
        status: null,
        browserValue: null,
        browserValueVirtual: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        name: [
          { required: true, message: '称号名称不能为空', trigger: 'blur' }
        ],
        cover: [
          { required: true, message: '展会封面不能为空', trigger: 'blur' }
        ],
        shareCover: [
          { required: true, message: '分享封面不能为空', trigger: 'blur' }
        ],
        banner: [
          { required: true, message: '展会详情banner不能为空', trigger: 'blur' }
        ],
        scriptNum: [
          { required: true, message: '参展剧本数不能为空', trigger: 'blur' }
        ],
        city: [
          { required: true, message: '城市名称不能为空', trigger: 'blur' }
        ],
        startTime: [
          { required: true, message: '开始时间不能为空', trigger: 'blur' }
        ],
        endTime: [
          { required: true, message: '结束时间不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '状态 1:预热中,2:火热进行中,3:圆满召开不能为空', trigger: 'blur' }
        ],
        version: [
          { required: true, message: '乐观锁不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.fileList = []
      this.form = {
        id: null,
        name: null,
        cover: null,
        shareCover: null,
        banner: null,
        mainColor: null,
        backgroundColor: null,
        scriptNum: null,
        city: null,
        startTime: null,
        endTime: null,
        status: null,
        browserValue: null,
        browserValueVirtual: null,
        createTime: null,
        updateTime: null,
        creator: null,
        modifier: null,
        isDeleted: null,
        remark: null,
        version: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getExpo(id).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = '修改'
        // 处理图片反显
        if (response.data.banner !== undefined && response.data.banner !== null && JSON.stringify(response.data.banner) !== '"{}"') {
          var images = this.form.banner.split(',')
          for (var i = 0; i < images.length; i++) {
            this.fileList.push({status: 'done', url: images[i], uid: this.getUidRandom(), name: 'banner.jpg'})
          }
        }
      })
    },
    onDateChange(date, dateString){
      console.log(date, dateString)
      this.form.startTime = dateString
    },
    onDateChange1(date, dateString){
      console.log(date, dateString)
      this.form.endTime = dateString
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            // this.form.startTime=parseTime(this.form.startTime)
            // this.form.endTime=parseTime(this.form.endTime)
            updateExpo(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            // this.form.startTime=parseTime(this.form.startTime)
            // this.form.endTime=parseTime(this.form.endTime)
            addExpo(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    // oss 上传开始
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await this.getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    // 上传之前校验
    beforeOssUpload(file) {
      const isImage = file.type.startsWith('image/')
      if (!isImage) {
        this.$message.error('请上传图片文件!')
        return false
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      // if (!isJPG) {
      //     this.$message.error('上传头像图片只能是 JPG 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 10MB!')
      }
      return isLt2M
    },
    // 上传用户头像覆盖默认的上传行为
    imgAvatarUpload(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'cover'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'cover', res)
          _this.$message.success('上传成功')
        })
      })
    },
    // 上传分享封面覆盖默认的上传行为
    imgAvatarUploadShare(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'shareCover'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)
          _this.$set(_this.form, 'shareCover', res)
          _this.$message.success('上传成功')
        })
      })
    },
    getUidRandom() {
      return Math.round(Math.random() * 80 + 20)
    },
    /**
     * 文件上传到oss返回url地址
     * @param { file } 文件对象
     */
    uploadAliYunOss(fileName, prefix) {
      // 获取文件后缀
      const suffix = fileName.substring(fileName.lastIndexOf('.'))
      // 生成文件名称
      const filePath = generateFilePath(prefix, suffix)
      return filePath
    },
    // oss 上传结束
    // 上传详情覆盖默认的上传行为
    imgAvatarUploadInfo(file, index) {
      const _this = this
      // 获取文件对象
      const uploadFile = file.file
      // 文件夹目录
      const prefix = 'banner'
      const url = _this.uploadAliYunOss(uploadFile.name, prefix)
      _this.$nextTick(() => {
        uploadObject(url, uploadFile).then(res => {
          console.log('上传结果:', res)

          _this.fileList.push({status: 'done', url: res, uid: this.getUidRandom(), name: 'banner.jpg'})
          var info = ''
          _this.fileList.forEach(e => {
            if (e.url !== undefined && e.url !== null && e.url !== '') {
              info += (e.url + ',')
            }
          })
          info = info.substring(0, info.length - 1)
          _this.$set(_this.form, 'banner', info)
          console.log('info=', _this.form.banner)
          _this.$message.success('上传成功')
        })
      })
    },
    // 删除图片
    removeFile(file) {
      var fileList = []
      this.fileList.forEach(e => {
        if (e.url !== file.url) {
          fileList.push(e)
        }
      })
      this.fileList = fileList
      var info = ''
      fileList.forEach(e => {
        if (e.url !== undefined && e.url !== null && e.url !== '') {
          info += (e.url + ',')
        }
      })
      info = info.substring(0, info.length - 1)
      this.$set(this.form, 'banner', info)
      console.log('info=', _this.form.banner)
    },
  }

}
</script>
